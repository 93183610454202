import { axiosInstance, request, requestConfig } from "./base";

export const POSTCertidao = async (certidaoRequest) => {
  return await request(() =>
    axiosInstance.post(
      `${requestConfig.api}api/v1/investidores/certidoes`,
      certidaoRequest
    )
  );
};
