export const data = [
    {
        "section" : {
            "name": "Horário dos serviços",
            "content": [
                {
                    "name": "Horário",
                    "link": "/docs/Laqus-HorarioDeFuncionamento.pdf"
                },
                {
                    "name": "Funcionamento em datas especiais e feriados",
                    "link": "/docs/Laqus-HorarioDeFuncionamento.pdf"
                }                
            ]
        }
    },
    {
        "section" : {
            "name": "Manuais",
            "content": [
                {
                    "name": "Manual Operacional de Direito de Acesso",
                    "link": "/docs/Laqus - Manual de Normas de Direito de Acesso.pdf"
                },
                {
                    "name": "Manual Operacional dos Ativos",
                    "link": "/docs/Laqus - Manual Operacional dos Instrumentos Financeiros.pdf"
                },
                {
                    "name": "Manual Operacional de Constrições e Garantias",
                    "link": "/docs/Laqus - Manual Operacional de Constrições e Garantias.pdf"
                },
                {
                    "name": "Manual de Normas de Direito de Acesso",
                    "link": "/docs/Laqus - Manual de Normas de Direito de Acesso.pdf"
                },
                {
                    "name": "Manual de Normas dos Ativos",
                    "link": "/docs/Laqus - Manual de Normas dos Instrumentos Financeiros.pdf"
                },
                {
                    "name": "Manual de Normas de Constrições e Garantia",
                    "link": "/docs/Laqus - Manual de Normas de Constrições e Garantias.pdf"
                },
                {
                    "name": "Manual de Interoperabilidade",
                    "link": "/docs/Laqus - Manual de Interoperabilidade.pdf"
                }
            ]
        }
    },
    {
        "section" : {
            "name": "Regulamento",
            "content": [
                {
                    "name": "Regulamento de Acesso e Operações",
                    "link": "/docs/Laqus - Regulamento de Acesso e Operações.pdf"
                }
            ]
        }
    },
    {
        "section" : {
            "name": "Outros Documentos",
            "content": [
                {
                    "name": "Termos de Adesão ao Regulamento",
                    "link": "/docs/Laqus-TermoAdesao.pdf"
                },
                {
                    "name": "Declaração de Custódia do Lastro - Modelo",
                    "link": "/docs/Laqus-DeclaracaoCustodia.pdf"
                }
            ]
        }
    }
]

  