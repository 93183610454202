
const invalidCpfs = ['00000000000', '11111111111', '22222222222', '33333333333', '44444444444', '55555555555', '66666666666', '77777777777', '88888888888', '99999999999'];
const invalidCnpjs = ['00000000000000', '11111111111111', '22222222222222', '33333333333333', '44444444444444', '55555555555555', '66666666666666', '77777777777777', '88888888888888', '99999999999999'];

export function validaCpfCnpj(val) {
  val = val.replace(/[\D]+/g, '');

  if (val === '') return false;

  let soma = 0;
  if (val.length === 11) {
    if (invalidCpfs.some(cpf => cpf === val))
      return false;

    let resto;
    for (let i = 1; i <= 9; i++) {
      soma = soma + +val.substring(i - 1, i) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== +val.substring(9, 10)) {
      return false;
    }
    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma = soma + +val.substring(i - 1, i) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== +val.substring(10, 11)) {
      return false;
    }
    return true;
  }

  if (val.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (invalidCnpjs.some(cnpj => cnpj === val))
    return false;

  // Valida DVs
  let tamanho = val.length - 2;
  let numeros = val.substring(0, tamanho);
  const digitos = val.substring(tamanho);
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += +numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== +digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = val.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += +numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== +digitos.charAt(1)) return false;

  return true;
}