import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import { createStyles, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    frame: {
      marginBottom: '20px',
      padding: '20px',
      border: `2px solid ${theme.palette.warning.main}`,
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export const WarningSign = ({ message }) => {
  const classes = useStyles();
  return (
    <div className={classes.frame}>
      <ErrorIcon style={{ marginRight: 10 }} fontSize="small" />
      <Typography variant="body1">{message}</Typography>
    </div>
  );
};
