import React from 'react';

import Header from './components/Header';
import Footer from './components/Footer';

import Home from './pages/Home';
import Extratos from './pages/Extrato';
import Certidoes from './pages/Certidoes';
import Documentos from './pages/Documentos'

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00E08E"
    },
    secondary: {
      main: "#fff"
    },
    error: {
      main: "#FF5A5F",
    },
    warning: {
      main: "#FFCF40"
    }
  },
  typography: {
    fontFamily: [
      'Circular',
      'Publico Bold',
      'Roboto'
    ].join(','),
    h1: {
      fontSize: "88px",
      fontWeight: 600,
      fontFamily: 'Publico Bold'
    },
    h2: {
      fontSize: "56px",
      fontWeight: 600,
      fontFamily: 'Publico Bold'
    },
    h3: {
      fontSize: "24px",
      fontWeight: 400,
      fontFamily: 'Publico Bold'
    },
    body1: {
      fontSize: "16px",
      fontFamily: 'Circular Bold',
      lineHeight: '24px'
    },
    body2: {
      fontSize: "16px",
      fontFamily: 'Circular',
      lineHeight: '24px'
    },
    subtitle1: {
      fontSize: "12px",
      fontFamily: 'Circular Bold',
      lineHeight: '18px'
    },
    subtitle2: {
      fontSize: "12px",
      fontFamily: 'Circular',
      lineHeight: '18px'
    },
    button: {
      fontFamily: 'Circular Bold',
      fontSize: '12px',
      lineHeight: '15px'
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '24px',
        fontSize: '12px',
        padding: '16px 30px'
      },
      sizeSmall: {
        padding: '10px 20px'
      },
      outlined: {
        padding: '16px 30px'
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: "#00E08E"
        }
      },
      containedSecondary: {
        border: `1px solid #00E08E`,
        "&:hover": {
          backgroundColor: "inherit"
        },
      },
      outlinedSecondary: {
        border: `1px solid black`,
        color: 'black',
        "&:hover": {
          border: `1px solid black`,
        },
      }
    }
  }
});

export default function Layout() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/extratos" component={Extratos} />
          <Route path="/certidoes" component={Certidoes} />
          <Route path="/documentos" component={Documentos} />
        </Switch>
        <Footer />
      </Router>
    </ThemeProvider>
  )
}
