import React from 'react'

import { Link } from "react-router-dom";

import imgHeroPc from '../../img/pc.png';

export default function Home() {
  return (
    <main className="main-content">
      <section className="hero-banner">
        <div className="container">
          <div className="hero-text">
            <h1 className="hero-title">
              A tecnologia que você sempre esperou no mercado financeiro
            </h1>
            <h3 className="hero-subtitle">
              Desbravando o mercado de depósitos de valores mobiliários e trazendo inovação a cada novo passo. Faça parte da Laqus agora mesmo!
            </h3>
          </div>
        </div>
        <div className="hero-image">
          <img src={imgHeroPc} alt="" />
        </div>
      </section>
     
    </main>
  )
}
