import React, { useState } from 'react'
import { Grid, Typography, Divider } from '@material-ui/core';
import { InsertDriveFile, GetApp } from '@material-ui/icons';
import { data } from './data.js';

export default function Documentos() {
    return (
        <div style={{ padding: 40 }}>
            <Typography align="center" variant="h2">{'Documentos'}</Typography>
            <div className="space-lg"></div>
            {data.map((_, i) => (
                <div key={i}>
                    <Divider variant="fullWidth" />
                    <div style={{ padding: '0px' }}>
                        <div className="space-lg"></div>
                        <Grid container alignItems="flex-start" spacing={4} style={{ width: '100%' }}>
                        <Grid item md={12} lg={3}>
                            <Typography variant="h3">{_.section.name}</Typography>
                        </Grid>
                        <Grid item lg={6}>
                            {_.section.content.map((_, i) => (
                                <>
                                    <Grid container spacing={1} style={{ width: "100%" }}>
                                        <Grid item>
                                            <InsertDriveFile />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">{_.name}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <a href={_.link} target="_blank">
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <GetApp style={{ marginRight: 5 }} />
                                                    <Typography variant="button">{'Download'}</Typography>
                                                </div>
                                            </a>
                                        </Grid>
                                    </Grid>
                                    <div className="space-sm"></div>
                                </>
                            ))}
                        </Grid>
                        </Grid>
                        <div className="space-lg"></div>
                    </div>
                </div>
            ))}
        </div>
    );
}