import { Button, Grid, TextField, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { POSTExtrato } from "requests/extrato";
import { validaCpfCnpj } from "utils/cpfCnpj";
import { WarningSign } from "components/warningSign";

export default function Extratos() {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    type: "",
    body: "",
  });
  const [nomeSolicitante, setNomeSolicitante] = useState("");
  const [cpfCnpjSolicitante, setCpfCnpjSolicitante] = useState("");
  const [nomeInvestidor, setNomeInvestidor] = useState("");
  const [cpfCnpjInvestidor, setCpfCnpjInvestidor] = useState("");

  const clearForm = () => {
    setNomeSolicitante("");
    setCpfCnpjSolicitante("");
    setNomeInvestidor("");
    setCpfCnpjInvestidor("");
  };

  useEffect(() => {
    if (!!message?.body) {
      setTimeout(() => setMessage(undefined), 10000);
    }
  }, [message]);

  const handleSubmit = (e) => {
    if (
      !validaCpfCnpj(cpfCnpjSolicitante) ||
      !validaCpfCnpj(cpfCnpjInvestidor)
    ) {
      setMessage({ type: "error", body: "Campo de CPF/CNPJ inválido!" });
      return;
    }

    e.preventDefault();
    setIsLoading(true);

    const extratoRequest = {
      nomeSolicitante,
      cpfCnpjSolicitante: cpfCnpjSolicitante.replace(/\D+/g, ""),
      nomeInvestidor,
      cpfCnpjInvestidor: cpfCnpjInvestidor.replace(/\D+/g, ""),
    };

    POSTExtrato(extratoRequest)
      .then(() => {
        setMessage({
          type: "primary",
          body:
            "Solicitação realizada com sucesso. O Extrato de Posição, Movimentação e Eventos Financeiros será enviado para o e-mail informado em até 72h, verifique também a sua caixa de Spam. Agradecemos por estar conosco!",
        });
        clearForm();
        setIsLoading(false);
      })
      .catch((error) => {
        if (error && error.response) {
          setMessage({
            type: "error",
            body: error.response?.data.exceptionMessage ?? error.message,
          });
        } else {
          setMessage({ type: "error", body: "Erro ao enviar o formulário!" });
        }
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div style={{ maxWidth: 450, margin: "0 auto" }}>
      <div className="space-lg" />
      <Typography variant="h2" align="center">
        {"Extratos"}
      </Typography>
      <div className="space-md"></div>
      <Grid spacing={3} container alignItems="center">
        <Grid item sm={12}>
          <TextField
            value={nomeSolicitante}
            onChange={(e) => setNomeSolicitante(e.target.value)}
            fullWidth
            required
            label="Nome do Solicitante"
            variant="outlined"
          />
        </Grid>
        <Grid item sm={12}>
          <InputMask
            mask={
              cpfCnpjSolicitante?.replace(/[^0-9]/g, "").length <= 11
                ? "999.999.999-999"
                : "99.999.999/9999-99"
            }
            value={cpfCnpjSolicitante}
            maskChar=" "
            onChange={(e) => setCpfCnpjSolicitante(e.target.value)}
          >
            {() => (
              <TextField
                fullWidth
                required
                label="CPF/CNPJ do Solicitante"
                variant="outlined"
              />
            )}
          </InputMask>
        </Grid>
        <Grid item sm={12}>
          <TextField
            value={nomeInvestidor}
            onChange={(e) => setNomeInvestidor(e.target.value)}
            fullWidth
            required
            label="Nome do Investidor"
            variant="outlined"
          />
        </Grid>
        <Grid item sm={12}>
          <InputMask
            mask={
              cpfCnpjInvestidor?.replace(/[^0-9]/g, "").length <= 11
                ? "999.999.999-999"
                : "99.999.999/9999-99"
            }
            value={cpfCnpjInvestidor}
            maskChar=" "
            onChange={(e) => setCpfCnpjInvestidor(e.target.value)}
          >
            {() => (
              <TextField
                fullWidth
                required
                label="CPF/CNPJ do Investidor"
                variant="outlined"
              />
            )}
          </InputMask>
        </Grid>
        {isLoading && (
          <Grid item sm={12} style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </Grid>
        )}
        {!!message?.body && (
          <Grid item sm={12}>
            <Typography variant="body2" color={message.type ?? "error"}>
              {message.body}
            </Typography>
          </Grid>
        )}
        <Grid item sm={12} style={{ textAlign: "center" }}>
          <WarningSign
            message={
              "Para posições de Fechamento (fim do dia), solicitar a partir das 19h de Brasília"
            }
          />
          <Button
            onClick={(e) => handleSubmit(e)}
            disabled={
              isLoading ||
              !nomeSolicitante ||
              !cpfCnpjSolicitante ||
              !nomeInvestidor ||
              !cpfCnpjInvestidor
            }
            variant="contained"
            color="primary"
          >
            {"Solicitar"}
          </Button>
        </Grid>
      </Grid>
      <div className="space-md" />
    </div>
  );
}
