import React from 'react'
import { Link } from 'react-router-dom'

import Logo from '../../img/logo.png'
import { Button, Typography, Grid } from "@material-ui/core";

export default function Header() {
  return (
    <div style={{width: '100%', marginTop:'-20px', display: 'flex', justifyContent: 'space-between', padding: 40, alignItems: 'center' }}>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <Link to='/' className="logo-site">
            <img src={Logo} alt="Laqus" />
          </Link>
        </Grid>
        <Grid item>
          <Link to='/'>
            <Typography variant="button">Home</Typography>
          </Link>
        </Grid>
        <Grid item>
          <Link to='/extratos'>
            <Typography variant="button">Extratos</Typography>
          </Link>
        </Grid>
        <Grid item>
          <Link to='/certidoes'>
            <Typography variant="button">Certidões</Typography>
          </Link>
        </Grid>  
        <Grid item>
          <Link to='/documentos'>
            <Typography variant="button">Documentos</Typography>
          </Link>
        </Grid>
        <Grid item>
          <a href="https://www.laqus.com.br" target="_blank">
            <Typography variant="button">Laqus</Typography>
          </a>
        </Grid>
      </Grid>
      <Grid container justify="flex-end" alignItems="center">
        <Button variant="outlined" color="primary" onClick={() => 
          window.open(process.env['REACT_APP_PLATAFORMA_URL'], "_blank")}
        >Área do Participante</Button>
      </Grid>
    </div>
  )
}
