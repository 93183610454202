import { axiosInstance, request, requestConfig } from "./base";

export const POSTExtrato = async (extratoRequest) => {
  return await request(() =>
    axiosInstance.post(
      `${requestConfig.api}api/v1/investidores/extrato`,
      extratoRequest
    )
  );
};
