import { Button, Grid, TextField, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { POSTCertidao } from "requests/certidao";
import { validaCpfCnpj } from "utils/cpfCnpj";
import { validaEmail } from "utils/email";

export default function Certidoes() {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    type: "",
    body: "",
  });

  const [nomeSolicitante, setNomeSolicitante] = useState("");
  const [cpfCnpjSolicitante, setCpfCnpjSolicitante] = useState("");
  const [emailSolicitante, setEmailSolicitante] = useState("");
  const [cpfCnpjGarantidor, setCpfCnpjGarantidor] = useState("");
  const [codigoAtivo, setCodigoAtivo] = useState("");

  const clearForm = () => {
    setNomeSolicitante("");
    setCpfCnpjSolicitante("");
    setEmailSolicitante("");
    setCpfCnpjGarantidor("");
    setCodigoAtivo("");
  };

  useEffect(() => {
    if (!!message?.body) {
      setTimeout(() => setMessage(undefined), 10000);
    }
  }, [message]);

  const handleSubmit = async (e) => {
    if (!validaEmail(emailSolicitante)) {
      setMessage({ type: "error", body: "Campo de e-mail inválido!" });
      return;
    }

    if (
      !validaCpfCnpj(cpfCnpjSolicitante) ||
      !validaCpfCnpj(cpfCnpjGarantidor)
    ) {
      setMessage({ type: "error", body: "Campo de CPF/CNPJ inválido!" });
      return;
    }

    e.preventDefault();

    setIsLoading(true);

    const certidaoRequest = {
      nomeSolicitante,
      cpfCnpjSolicitante: cpfCnpjSolicitante.replace(/\D+/g, ""),
      emailSolicitante,
      cpfCnpjGarantidor: cpfCnpjGarantidor.replace(/\D+/g, ""),
      codigoAtivo,
    };

    POSTCertidao(certidaoRequest)
      .then(() => {
        setMessage({
          type: "primary",
          body: "Solicitação realizada com sucesso. A Certidão será enviada para o e-mail informado, em até 72h. Verifique também a sua caixa de Spam."
        });
        clearForm();
        setIsLoading(false);
      })
      .catch((error) => {
        if (error && error.response) {
          setMessage({
            type: "error",
            body: error.response?.data.exceptionMessage ?? error.message,
          });
        } else {
          setMessage({ type: "error", body: "Erro ao enviar o formulário!" });
        }
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div style={{ maxWidth: 450, margin: "0 auto" }}>
      <div className="space-md"></div>
      <Typography variant="h2" align="center">
        {"Certidões"}
      </Typography>
      <div className="space-md"></div>
      <Grid spacing={3} container alignItems="center">
        <Grid item sm={12}>
          <TextField
            value={nomeSolicitante}
            onChange={(e) => setNomeSolicitante(e.target.value)}
            fullWidth
            required
            label="Nome do Solicitante"
            variant="outlined"
          />
        </Grid>
        <Grid item sm={12}>
          <InputMask
            mask={
              cpfCnpjSolicitante?.replace(/[^0-9]/g, "").length <= 11
                ? "999.999.999-999"
                : "99.999.999/9999-99"
            }
            value={cpfCnpjSolicitante}
            maskChar=" "
            onChange={(e) => setCpfCnpjSolicitante(e.target.value)}
          >
            {() => (
              <TextField
                fullWidth
                required
                label="CPF/CNPJ do Solicitante"
                variant="outlined"
              />
            )}
          </InputMask>
        </Grid>
        <Grid item sm={12}>
          <TextField
            value={emailSolicitante}
            onChange={(e) => setEmailSolicitante(e.target.value)}
            fullWidth
            required
            label="E-mail do Solicitante"
            variant="outlined"
          />
        </Grid>
        <Grid item sm={12}>
          <InputMask
            mask={
              cpfCnpjGarantidor?.replace(/[^0-9]/g, "").length <= 11
                ? "999.999.999-999"
                : "99.999.999/9999-99"
            }
            value={cpfCnpjGarantidor}
            maskChar=" "
            onChange={(e) => setCpfCnpjGarantidor(e.target.value)}
          >
            {() => (
              <TextField
                fullWidth
                required
                label="CPF/CNPJ do Garantidor"
                variant="outlined"
              />
            )}
          </InputMask>
        </Grid>
        <Grid item sm={12}>
          <TextField
            value={codigoAtivo}
            onChange={(e) => setCodigoAtivo(e.target.value)}
            fullWidth
            label="Código do Ativo"
            required
            variant="outlined"
          />
        </Grid>
        {isLoading && (
          <Grid item sm={12} style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </Grid>
        )}
        {!!message?.body && (
          <Grid item sm={12}>
            <Typography variant="body2" color={message.type ?? "error"}>
              {message.body}
            </Typography>
          </Grid>
        )}
        <Grid item sm={12} style={{ textAlign: "center" }}>
          <Button
            onClick={(e) => handleSubmit(e)}
            disabled={
              isLoading ||
              !nomeSolicitante ||
              !cpfCnpjSolicitante ||
              !emailSolicitante ||
              !cpfCnpjGarantidor ||
              !codigoAtivo
            }
            variant="contained"
            color="primary"
          >
            {"Solicitar"}
          </Button>
        </Grid>
      </Grid>
      <div className="space-md"></div>
    </div>
  );
}
