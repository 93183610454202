import React from 'react'

import { Link } from 'react-router-dom'

import Logo from '../../img/logo-footer.png';
import { Typography } from "@material-ui/core";

export default function Footer() {
  return (
    <footer className="footer-site">
      <div style={{ width: '100%', height: 1, backgroundColor: '#E5E5E5', marginBottom: 40}}></div>
      <div className="container">
        <div className="footer--logo">
          <img src={Logo} alt="Laqus" />
        </div>
        <nav className="footer--nav">
          <Link to='/'>
            <Typography variant="body1">Home</Typography>
          </Link>
          <Link to='/extratos'>
            <Typography variant="body1">Extratos</Typography>
          </Link>
          <Link to='/certidoes'>
            <Typography variant="body1">Certidões</Typography>
          </Link> 
          <Link to='/documentos'>
            <Typography variant="body1">Documentos</Typography>
          </Link> 
          <a href="https://www.laqus.com.br" target="_blank">
            <Typography variant="body1">Laqus</Typography>
          </a>
        </nav>
        <div className="footer--info-contato">
          <b>Contato</b>
          <p>contato@laqus.com.br</p>
          <p>Av. Pedroso de Morais, 433 - Pinheiros - São Paulo</p>
          <p>(11) 3552-4022</p>
        </div>
      </div>
    </footer>
  )
}
