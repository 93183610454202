import axios from "axios";

export const requestConfig = {
  api: process.env.REACT_APP_API_BASE_URL || "",
};

export const axiosInstance = axios.create({
  baseURL: requestConfig.api,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  },
});

export async function request(
  axiosRequest,
) {
  const result = await axiosRequest();
  return result.data;
}
